import React, { Component } from 'react';
import { graphql } from 'gatsby';
import PageBuilder from 'components/PageBuilder';
import Seo from 'components/Seo';
import Hero404 from 'components/Hero404';

class Page404 extends Component {
  render() {
    const {
      data: {
        craft: { entry },
      },
    } = this.props;

    return (
      <>
        <Seo
          title={entry.seoTitle || entry.title}
          description={entry.seoDescription}
        />
        {entry.hero404.length > 0 && <Hero404 content={entry.hero404} />}
        {entry.pageBuilder.length > 0 && (
          <PageBuilder pageBuilder={entry.pageBuilder} />
        )}
      </>
    );
  }
}

export default Page404;

export const pageQuery = graphql`
  query notFoundPage {
    craft {
      entry(section: page404) {
        ... on Craft_Page404 {
          seoTitle
          seoDescription
          hero404 {
            ...Hero404Query
          }
          pageBuilder {
            ...PageBuilderQuery
          }
        }
      }
    }
  }
`;
