import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { css } from 'emotion';
import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import Headline from 'components/Headline';
import Container from 'components/Container';
import Section from 'components/Section';
import Text from 'components/Text';
import { buttonSecondary } from 'components/Button/Button';
import { bgGradientBlueOverlay50 } from 'components/Gradients';
import Link from 'components/Link';

const heroSection = css({
  backgroundSize: 'cover',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  zIndex: '0',
  minHeight: '100vh',
});

const HeroContent = styled('div')({
  maxWidth: 400,
  textAlign: 'center',
  color: 'white',
  padding: '50px 0',
  margin: '0 auto',
});

const HeadlineStyled = styled(Headline)({
  marginTop: 50,
  paddingBottom: 20,

  '&.h1': {
    fontSize: 150,
    lineHeight: '1',
  },
});

const TextStyled = styled(Text)({
  fontSize: 20,
  paddingBottom: 40,
  fontWeight: 600,
});

export default class Hero404 extends Component {
  render() {
    const {
      headline,
      text,
      backgroundImage,
      buttonText,
      pageBuilder,
    } = this.props.content[0];

    const bgImage = backgroundImage[0]
      ? `url(' ${backgroundImage[0].hero} ')`
      : 'none';

    const styles = {
      backgroundImage: `${bgImage}`,
      backgroundPosition: '50% 0%',
    };

    return (
      <Section
        id={this.props.id}
        style={styles}
        className={`${bgGradientBlueOverlay50} ${heroSection}`}
      >
        <Container>
          <HeroContent>
            {headline && <HeadlineStyled tag="h1">{headline}</HeadlineStyled>}
            {text && <TextStyled tag="div">{text}</TextStyled>}
            {buttonText && (
              <Link to="/" className={buttonSecondary}>
                {buttonText}
              </Link>
            )}
          </HeroContent>
        </Container>
      </Section>
    );
  }
}

export const query = graphql`
  fragment Hero404Query on Craft_Hero404Hero {
    headline
    text
    buttonText
    backgroundImage {
      url
      hero: url(transform: transformHero)
    }
  }
`;
